import React from 'react';
import mangoImage from '../../images/d.jpg';
import './indexa.css';

function MangoWebsite() {
  return (
    <div className="mango-container">
      <div className="mango-header">
        <h1>Export Quality</h1>
        <img src={mangoImage} alt="Mango" />
      </div>
      <div className="mango-content">
        <p>Our website offers the freshest and sweetest mangoes from around the world. We have a wide selection of mango varieties and sizes to choose from. Whether you are a mango enthusiast or just looking for a healthy snack, we have something for everyone.</p>
      
      </div>
    </div>
  );
}

export default MangoWebsite;
