import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import "./OrderForm.css";


const OrderForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [city, setCity] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      sendEmail(e);
    } else {
      setErrors(errors);
    }
  };
  function AlertMessage() {
    return (
        <div class = "alert alert-success">
        <a href = "#" class = "close" data-dismiss = "alert">
           &times;
        </a>
        
        <strong>Warning!</strong> There was a problem with your network connection.
     </div>
    );
  }
  const validateForm = () => {
    let errors = {};

    if (!name.trim()) {
      errors.name = 'Name is required';
    }

    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Invalid email address';
    }

    if (!address.trim()) {
      errors.address = 'Address is required';
    }

    if (!phone.trim()) {
      errors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(phone)) {
      errors.phone = 'Invalid phone number';
    }
    
  if (!city.trim()) {
    errors.city = 'City is required';
  }

    return errors;
  };

  const sendEmail = (e) => {
    var myMessage ="<div><h1>Hello World!</h1><p>This is some HTML code</p></div>"
    e.preventDefault();
    emailjs.sendForm('service_mg8ejj8', 'template_jbbgnqg', e.target, 'iZ6ydfEudj1mH7rMM')
      .then((result) => {
        console.log(result.text);
        setName('');
        setEmail('');
        setAddress('');
        setPhone('');
        setCity('');
        setMessage('');
        setErrors({});
       
        alert("YOUR ORDER IS SUCCESSFUL YOU WILL RECEIVE MAIL");
      }, (error) => {
        console.log(error.text);
      });
  }; 
 
  return (
    <div className="order-form-container mt-3">
      <form onSubmit={handleSubmit}>
        <div>
            <h2 style={{color:"orange",display:"flex",justifyContent:"center"}}>Quick Order Now <span>&#128512;</span></h2>
        </div>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>
        <div className="form-group">
  <label htmlFor="city">City</label>
  <select
    id="city"
    name="city"
    value={city}
    onChange={(e) => setCity(e.target.value)}
  >
    <option value="">Select a city</option>
    <option value="Gandhinagr">Gandhinagar</option>
    <option value="Himmatnger">Himmatnagar</option>
    <option value="Mahesana">Mahesana</option>
    <option value="Ahmedabad">Ahmedabad</option>
  
  </select>
  {errors.city && <span className="error">{errors.city}</span>}
</div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            id="address"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          {errors.address && <span className="error">{errors.address}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          {errors.phone && <span className="error">{errors.phone}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <button type="submit" class="submit-order-btn">Submit Order</button>
        <div className="payment-info1">
        After the successful order you will receive a confirmation Email from our team.
    </div>
        <div className="payment-info">
      Cash on Delivery only.
    </div>
      </form>
    </div>
    
  )}
export default OrderForm;