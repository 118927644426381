import React from 'react';
import mangoImage from '../../images/d1.jpg';
import './homes.css';

function HomePage() {
  return (
    <div className="home-container">
      <div className="hero-image">
        <img src={mangoImage} alt="Mango" />
      </div>
      <div className="home-content">
        <h1>Welcome to Mango Website</h1>
        <p>Our website offers the freshest and sweetest mangoes from around the world. We have a wide selection of mango varieties and sizes to choose from. Whether you are a mango enthusiast or just looking for a healthy snack, we have something for everyone.</p>
        <button className="order-now-button">Order Now</button>
      </div>
    </div>
  );
}

export default HomePage;
