import React,{useState} from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./Header.css";

const Header = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    if(window.innerWidth<1000)
    setExpanded(!expanded);
  };

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

  return (
    <Navbar
    expanded={expanded}
      className="container-fluid sticky-top shadow-sm   HeaderCss"
      expand="lg"
    >
      <Container>
        <Navbar.Brand onClick={()=>scrollToTop()}>
          <Link to="/" className="active" >
            <img
              src={require("../../images/Altamonte_Dermatology_logos/Altamonte Dermatology logos/Altamonte mangog.gif")}
              alt="logo"
              className="logoCss" style={{height: "50px" , width: "50px"}}
            />
            
          </Link>
        
        </Navbar.Brand>
        <div style={{display:"d-flex"}}>
            <h2 style={{color:"#FFA500",display:"flex"}} className="dhairya">Brothers Mango</h2>
            </div>
        <Navbar.Toggle aria-controls="navbarScroll "  onClick={handleToggle}/>
        <Navbar.Collapse
          id="navbarScroll"
          className="text-center bg-white pb-5 pb-lg-0"
        >
       
          <Nav className="mx-auto my-2 my-lg-0 "  navbarScroll onClick={()=>scrollToTop()}>

           
             <Nav.Link className="hoverClass"  onClick={handleToggle}>
              <Link to="/About" id="list" data-toggle="collapse" data-target=".navbar-toggler collapsed" >
                {" "}
                About
              </Link>
            </Nav.Link>
            <Nav.Link className="hoverClass"  onClick={handleToggle}>
              <Link to="/Servicepage" id="list" activeClassName="is-active">
                {" "}
                Services
              </Link> 
            </Nav.Link>
            {/* <Nav.Link className="hoverClass"  onClick={handleToggle}>
              <Link to="/InsuranceAndForms" id="list" activeClassName="is-active">
                {" "}
                Insurance & Forms
              </Link>
            </Nav.Link>
            <Nav.Link className="hoverClass"  onClick={handleToggle}>
              <Link to="/Contact" id="list" activeClassName="is-active">
                {" "}
                Contact
              </Link>
            </Nav.Link> */}
            {/* <div className="d-flex d-none d-lg-block align-items-center fw-bold h4 vrcolor" > | </div> */}
            {/* <Nav.Link
             onClick={handleToggle}
              className="hoverClass"
              href="https://altamontedermatology.ema.md/"
            > */}
              {/* <Link id="list" activeClassName="is-active"> */}
             
              {/* </Link> */}
            {/* </Nav.Link> */}
            <Nav.Link
             onClick={handleToggle}
              className="hoverClass"
              href="https://altamontedermatology.ema.md/ema/pay/onlinepayments"
            >
              
            </Nav.Link>
          </Nav>
          <Navbar.Brand>
            <Link to="" className="customCss1"  onClick={handleToggle}>
              {" "}
              <button  type = "button" className="px-3 py-2  customCss" onClick={()=>window.location.href=`tel:8238320030`}>
                <img
                  src={require("../../images/Logo/11.png")}
                  className="iconWidth"
                  alt="logo1"
                />{" "}
                &nbsp;+91 8238320030
              </button>{" "}
            </Link>
          </Navbar.Brand>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;