import React from "react";
import "./Service.css";
import Header from "../Header/Header";
import Seo from "../Seo";

const Service = () => {
  return (
    <>
   <Seo
        title="Mango Services | Your Website"
        description="Discover our mango-related services at Your Website. We offer a range of solutions to enhance your mango experience."
        keywords="mango services, mango solutions, mango offerings, mango experts"
      />
      <div className="container">
        <h1>Our Mango Services</h1>
        <div className="services-grid">
          <div className="service-card">
            <img src="https://kj1bcdn.b-cdn.net/media/22487/mango-palnt.jpg?width=200" alt="Mango Farming Consultation" />
            <h3>Mango Farming Consultation</h3>
            <p>Get expert advice on mango cultivation, orchard management, and best practices for a successful harvest.</p>
            <a href="/contact">Contact Us</a>
          </div>
          <div className="service-card">
            <img  src="https://www.hlimg.com/images/events/738X538/fgvrefgvg_1537342226e.jpg" width={200} alt="Mango Tasting Events" />
            <h3>Mango Tasting Events</h3>
            <p>Join our exclusive mango tasting events to savor a variety of mango flavors and learn about their unique characteristics.</p>
            <a href="/events">View Events</a>
          </div>
          <div className="service-card">
            <img src="https://imademycake.com/wp-content/uploads/2020/06/mango-cream-featured.png"  width={200} height={150} alt="Mango Recipe Development" />
            <h3>Mango Recipe Development</h3>
            <p>Discover mouthwatering mango recipes created by our culinary experts to bring out the best of this delicious fruit.</p>
            <a href="/recipes">Explore Recipes</a>
          </div>
          <div className="service-card">
            <img src="https://www.sendbestgift.com/media/images/product/56af305fe2c4c1e2c1a194dd2a78309d.webp" width={200} height={150} alt="Mango Gift Baskets" />
            <h3>Mango Gift Baskets</h3>
            <p>Impress your loved ones with beautifully curated mango gift baskets featuring premium mango varieties and delectable treats.</p>
            <a href="/shop">Shop Now</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
