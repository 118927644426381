import React,{useEffect, useState} from "react";
import "./Home.css";
// import Map from "../Map";
// import { Link } from "react-router-dom";
import OrderForm from "./Header/orderForm";
import MangoWebsite from "./Header/MangoContent";
import HomePage from "./Header/Homes";
import Seo from "./Seo";
import { Analytics } from '@vercel/analytics/react';
// import { useScript } from 'react-async-script-loader';


const Home = () => {
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    useEffect(() => {
      const script = document.createElement("script");
      script.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.async = true;
  
      script.onload = () => {
        setIsScriptLoaded(true);
      };
  
      document.head.appendChild(script);
  
      return () => {
        document.head.removeChild(script);
      };
    }, []);

  return (
    <>
      {/* <div className='container-fluid px-0'>
        <div className='row mx-0 px-5 home-background-image d-flex align-items-center'>
            <div className='col-12 mx-0 px-5  '>
                <div className=' bg_home_square  position-relative bg_home_margin '> 
                  <p className='home_font_size text-white text-start  square_padding  mb-0 '>Your skin, our passion <br></br>Your partner in dermatology care</p>
                </div>
                <p className='text-white home_font_size1 text-start line_padding'>Experts in the early detection, prevention and treatment of skin cancer </p>
                <button type="button" class="d-flex justify-content-start align-items-center bg-white home_btn btn_bg line_margin">Make An Appointments</button>
            </div>
        </div>
    </div> */}
      {isScriptLoaded ? (
<>

<Seo
title="Your Website | Home"
description="This is the homepage of Your Website."
keywords="website,brothersmango, home, keywords Fresh Mangoes,
Exotic Mangoes,
Mango Farming,
Mango Varieties,
Mango Recipes,
Mango Nutrition,
Mango Health Benefitss,
Mango Desserts,
Mango Smoothies,
Mango Sourcing,
Organic Mangoes,
Mango Season,
Mango Exporters,
Mango Gifts,
Mango Festival,
Mango Industry,
Mango Wholesale,
Mango Farm Tours,
Mango Packaging,
Mango Delicacies,
Mango Orchard,
Mango Cultivation,
Mango Harvesting,
Mango Ripening,
Mango Preservation,
Mango Export,
Mango Import,
Mango Seasonal Availability,
Mango Farm Management,
Mango Picking Techniques,
Mango Tasting,
Mango Hybrid Varieties,
Mango Plantation,
Mango Processing,
Mango Jam,
Mango Chutney,
Mango Ice Cream,
Mango Beverages,
Mango Tea,
Mango Smoothie Bowls,
Mango Salad,
Mango Salsa,
Mango Sauce,
Mango Cocktail Recipes,
Mango Health Supplements,
Mango Beauty Products,
Mango Inspired Art,
Mango Festival Events,
Mango Trade Associations,
Mango Industry Trends,

Trustable mangoes,
High-quality mangoes,
Clean mangoes,
Mango sourcing,
Sustainable mango farming,
Mango export services,
Fresh mango delivery,
Mango industry expertise,
Mango tasting events,
Mango recipe development,
Mango gift baskets,
Mango farm consultation,
Mango quality control,
International mango shipping,
Eco-friendly mango practices,
Customer satisfaction with mangoes,
Premium mango varieties,
Reliable mango supplier,
Organic mango farming,
Mango freshness guarantee,
"
/>




      <div className="container-fluid px-0">
        <div className="row mx-0 px-0 home_bg_img d-flex align-items-center justify-content-center">
          <div className="col-10 px-0 mt-md-5">
            <div className="home_square d-flex align-items-center">
              <div className="">
                <p className="text-white titile_font ps-5 d-none d-md-block">
                  {" "}
                  SUMMER IS HERE <br />
                  
                </p>
                <p className="text-white titile_font ps-4 ps-sm-5  d-md-none">
                  {" "}
                  SUMMER IS HERE  
                </p>
              </div>
            </div>
           
                <p className="text-white home_font_size1 text-start py-sm-4 py-lg-4  ">
                Enjoy Our Natural and Fresh Mango With Your Family{" "}
                </p>
                {/* <button
                  type="button"
                  class="d-flex justify-content-start align-items-center bg-white home_btn btn_bg py-4 px-4"
                >
                  Make an Appointment




                </button> */}
             
          </div>
        </div>
      </div>

      <div className="container px-0 my-0 my-sm-5">
        <div className="row mx-0 px-0  py-5    ">
          <div className=" col-12 col-lg-5 mx-0 px-0 section2_bg position-relative d-flex justify-content-center ">
            <div className="row mx-0 px-0 justify-content-between  ">
              <div className="col-12 mx-0 px-0 pt-5">
                <img
                  src={require("../images/dermatologist_main/mango4.jpg")}
                  alt="img"
                  className="px-3 px-sm-0 img-fluid"
                ></img>
              </div>
            </div>
          </div>
                    
          <div className="col-12 col-lg-7 mx-0  section2_leftpadding ps-lg-5">
          <div className="text-start sec2_title sec2_title_mobile py-5 py-lg-0 text-center text-lg-start">
  <ins className="adsbygoogle"
    style={{ display: 'block' }}
    data-ad-client="ca-pub-2271117209197235"
    data-ad-slot="6167726327"
    data-ad-format="auto"
    data-full-width-responsive="true"
  ></ins>
  Welcome to Brothers Mango
</div>

            <p id="sec2_fonttitle">
              Our mission is to provide our costomers with the high quality and fresh mango.
              We provide mango that ripe without any preservatives and chemical.Our mangoes are 
              organic and naturally riped. 
              
              
            </p>
            <p>Our team is dedicated to ensuring that our mangoes are of the highest quality and meet our rigorous standards. We work closely with our growers and suppliers to ensure that our mangoes are fresh and delivered to our customers in a timely manner.</p>
            <p>Our mangoes are available for purchase through various channels, including local markets, wholesalers, retailers, and e-commerce platforms. We are committed to providing our customers with the best possible service and support.</p>
          </div>
        </div>
      </div>
      <Analytics />

      <div className="container ">
        <div className="row mx-0 px-0 d-flex align-items-center">
          <div className=" col-12 col-lg-6 mx-0 pe-lg-5  ps-0 ">
            <div className="text-start sec2_title sec2_title_mobile pb-3 text_dark py-5 py-lg-0 text-center  text-lg-start">
              Our Vision
            </div>
            <p className="text-start sec2_p">
              Our vision is to be the leading mango market in the area,
              providing high-quality, fresh and organic mango to all customers. We are
              committed to providing our customers with the best and exported quality mango.
              
            </p>
            <div className="d-flex justify-content-lg-start justify-content-center ">
            </div>
          </div>
          <div className=" col-12 col-lg-6 mx-0 px-0 position-relative order-first order-lg-last ">
            <img
              src={require("../images/dermatologist_main/mango3.jpg")}
              className=" img-fluid sec3_img"
              alt="img"
            ></img>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <MangoWebsite/>
      </div>
  <div className="container">
     <OrderForm/>
  </div>
           

  <div className="home-page">
      <div className="trustable-mangoes">
        <h2>Trustable Mangoes</h2>
        <p>At Your Website, we take pride in offering the finest and most delicious mangoes. Our mangoes are carefully sourced from trusted orchards known for their high-quality produce.</p>
        <p>We work closely with our partner farmers who follow sustainable agricultural practices, ensuring that our mangoes are grown without the use of harmful chemicals or pesticides.</p>
        <p>With our commitment to quality and authenticity, you can trust that every mango you purchase from us is of the highest standard.</p>
      </div>
      
      <div className="clean-mangoes">
        <h2>Clean Mangoes</h2>
        <p>Hygiene and cleanliness are of utmost importance to us. Our mangoes undergo rigorous cleaning and quality control processes to ensure that you receive clean and safe fruit.</p>
        <p>We adhere to strict quality standards and follow industry best practices to maintain the hygiene and freshness of our mangoes at every stage of the supply chain.</p>
        <p>From harvesting to packaging, we prioritize cleanliness to provide you with mangoes that are not only delicious but also free from contaminants.</p>
      </div>
      
      <div className="export-mangoes">
        <h2>Export Mangoes</h2>
        <p>As a trusted exporter of mangoes, we take great care in delivering our mangoes to customers worldwide.</p>
        <p>Our export-quality mangoes are meticulously sorted, graded, and packaged to meet international standards and ensure their freshness and taste during transit.</p>
        <p>We work closely with reliable shipping partners to ensure that our mangoes reach you in the best possible condition, no matter where you are in the world.</p>
      </div>
      
      <div className="sustainable-practices">
        <h2>Sustainable Practices</h2>
        <p>We are committed to sustainable practices and strive to minimize our environmental impact.</p>
        <p>We actively support eco-friendly initiatives, such as responsible water management, organic farming, and minimizing waste throughout our operations.</p>
        <p>By choosing our mangoes, you contribute to the preservation of the environment and support sustainable agriculture.</p>
      </div>
      
      <div className="customer-satisfaction">
        <h2>Customer Satisfaction</h2>
        <p>Your satisfaction is our top priority. We aim to provide you with an exceptional mango experience from the moment you place your order to the first juicy bite.</p>
        <p>We value your feedback and continuously strive to improve our products and services to meet and exceed your expectations.</p>
        <p>Experience the joy of our delicious mangoes and join our community of happy and satisfied customers.</p>
      </div>
      
    </div>

   

    </>
    
      ) : (
        // Show a loading state or alternative content
        <div>Loading Google AdSense...</div>
      )}
    </>
  );
};

export default Home;
