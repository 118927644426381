import React from "react";
import "./About.css";
import Seo from "../Seo";

const About = () => {
  return (
    <>
      {/* <div className="conatiner-fluid">
        <div className="row mx-0 px-0 aboutus_banner">
          <div className="col-12 px-0 mx-0 d-flex align-items-center justify-content-center ">
            <img
              src={require("../../images/About/Shape.png")}
              alt="img"
              className=" h-75 img-fluid"
            ></img>
            <p className="text-white  banner_text position_title banner_text_titile">
              Meet Our Professional Staff
            </p>
          </div>
        </div>
      </div> */}

<Seo
        title="Fresh Mangoes for Sale | Your Website"
        description="Buy fresh and delicious mangoes from Your Website. We offer a wide variety of high-quality mangoes."
        keywords="mangoes for sale, fresh mangoes, buy mangoes, mango varieties"
      />

<div className="conatiner">
      <div className="hero-section">
        <div className="hero-content">
          <h1>Welcome to Brothers Website's Mango Shop!</h1>
          <p>
            Choose from our wide selection of fresh, juicy, and flavorful mangoes. We source our mangoes from the finest
            orchards to ensure you get the best quality fruit.
          </p>
          <a href="#mango-varieties" className="btn-primary">Explore Mango Varieties</a>
        </div>
        <div className="hero-image">
          <img src="https://img.freepik.com/premium-photo/ripe-mango-with-green-leaf-isolated-white_252965-183.jpg?w=200" height={300} alt="Fresh Mangoes" />
        </div>
      </div>
      
      <section id="mango-varieties" className="mango-varieties-section">
        <h2>Explore Our Mango Varieties:</h2>
        <div className="mango-grid">
          <div className="mango-card">
            <img src="https://aliveplant.com/wp-content/uploads/2021/09/aphonso.jpeg" width={200}></img>
            <h3>Alphonso</h3>
            <p>A rich and creamy mango variety known for its sweet and aromatic flavor.</p>
          </div>
          <div className="mango-card">
          <img src="https://cdn.shopify.com/s/files/1/0552/5644/9180/products/kesar-mango.jpg?v=1664109920" width={200}></img>

            <h3>Kesar</h3>
            <p>A popular Indian mango variety with a unique sweet-tangy taste and vibrant color.</p>
          </div>
          <div className="mango-card">
          <img src="https://upload.wikimedia.org/wikipedia/commons/5/5f/Mango_TommyAtkins02_Asit.jpg" width={200}></img>
            <h3>Tommy Atkins</h3>
            <p>A globally recognized mango variety with a sweet and mildly tart flavor.</p>
          </div>
          <div className="mango-card">
          <img src="https://encrypted-tbn2.gstatic.com/licensed-image?q=tbn:ANd9GcQTCT_ABiRHI1N_9pQFn2egNwpmmUTnxtnvjk7Q16twoXoVYebgIlqfnON6fPVMi2GGWFxQTerIkh_Ehm4" width={200} height={150}></img>
            <h3>Keitt</h3>
            <p>A large-sized mango variety known for its juicy and slightly tangy taste.</p>
          </div>
          <div className="mango-card">
          <img src="https://encrypted-tbn3.gstatic.com/licensed-image?q=tbn:ANd9GcTEkgF0jLO8KKLUfXe3IKwKhFvJTLXhEjI7e7g5b7FQ9y77jbN6Tk8otULQj_IMEWA-VafaaFD29kUZhhg" width={200} height={150}></img>
            <h3>Haden</h3>
            <p>A classic mango variety with a sweet and tropical flavor profile.</p>
          </div>
        </div>
      </section>
      
      <section className="order-section">
        <div className="order-content">
          <h2>Place Your Mango Order Today!</h2>
          <p>Visit our online store or contact us to place your order for the freshest mangoes.</p>
        </div>
        <div className="order-image">
          <img src="https://img.freepik.com/free-vector/order-now-banner_23-2148711629.jpg?w=200" alt="Order Mangoes" />
        </div>
      </section>
      
      <section className="additional-info-section">
        <div className="additional-info-content">
          <h2>Why Choose Our Mangoes?</h2>
          <ul>
            <li>Handpicked from the best orchards to ensure premium quality</li>
            <li>Strict quality control measures to guarantee freshness</li>
            <li>Eco-friendly farming practices for sustainable mango production</li>
            <li>Delivered right to your doorstep for convenience</li>
          </ul>
        </div>
      </section>
      
      <section className="testimonial-section">
        <h2>What Our Customers Say:</h2>
        <div className="testimonial-grid">
          <div className="testimonial-card">
            <p>"The mangoes I ordered were absolutely delicious! The flavor was incredible, and they were perfectly ripe."</p>
            <p className="customer-name">- John Doe</p>
          </div>
          <div className="testimonial-card">
            <p>"I've tried various mango varieties from different places, but the ones I got from Your Website were by far the best!"</p>
            <p className="customer-name">- Jane Smith</p>
          </div>
        </div>
      </section>
      
      <section className="contact-section">
        <h2>Contact Us</h2>
        <p>Have any questions or need assistance? Reach out to our friendly customer support team.</p>
        <p>Phone: 8238320030</p>
        <p>Gandhinager  Gujarat India </p>
      </section>
</div>
    </>
  );
};

export default About;
